import React from "react";
import Layout from "../components/layout";
import Seo from "../components/Seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeaderUnderlined from "../components/HeaderUnderlined";
import ContactForm from "../components/ContactForm";
import { StaticImage } from "gatsby-plugin-image";

const Contacts = () => {
  return (
    <Layout>
      <Seo title="Контакты" />
      <Container fluid="lg">
        <Row>
          <Col
            xl={3}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            style={{ backgroundColor: "#a08285b3" }}
          >
            <div className="shadow-sm bg-secondary">
              <StaticImage
                src="../images/alexander-andrews-HgUDpaGPTEA-unsplash.jpg"
                formats={["AUTO", "WEBP", "AVIF"]}
                loading="eager"
                alt="Photo by Alexander Andrews on Unsplash"
                draggable="false"
                width={400}
              />
            </div>
          </Col>
          <Col className="p-5 bg-white">
            <HeaderUnderlined titlesrc="Контакты" />
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Contacts;
